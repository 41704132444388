<template>
  <div class='p-4 border rounded-md bg-white'>
    <textarea class='w-full p-2'
              rows='2'
              v-model='commentBody'>
    </textarea>
    <div class='flex flex-row justify-end '>
      <button class='text-xs mr-4'
              @click='closeEditInput'>취소</button>
      <button class='border text-center px-5 py-2 text-sm font-semibold'
              style='background-color: var(--eventMainColor); color: #FFF;'
              :class='commentSubmitButtonClass'
              :disabled='!hasCommentBody'
              @click='updateComment'>수정하기</button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
export default {
  name: 'CommentEditInput',
  props: ['editingCommentId', 'editingCommentBody', 'editingCommentParentCommentId'],
  data() {
    return {
      commentBody: ''
    }
  },
  watch: {
    'editingCommentBody': {
      handler: function (newVal) {
        if (newVal) {
          this.commentBody = newVal
        }
      },
      immediate: true
    },
  },
  computed: {
    ...mapState('comments', [
      'instance'
    ]),
    ...mapGetters('events', [
      'showingEventId',
      'showTextInKorean',
    ]),
    hasCommentBody () {
      return this.commentBody
    },
    commentSubmitButtonClass () {
      return this.hasCommentBody ? '' : 'opacity-25'
    },
    commentParams () {
      return {
        comment: {
          body: this.commentBody,
        }
      }
    },
    updateConfirmMessageText () {
      if (this.showTextInKorean) {
        if (this.editingCommentParentCommentId) {
          return '댓글이 수정되었습니다.'
        } else {
          return '질의 사항이 수정되었습니다.'
        }
      } else {
        if (this.editingCommentParentCommentId) {
          return 'This comment has been successfully updated.'
        } else {
          return 'This question has been successfully updated.'
        }
      }
    },
  },
  methods: {
    ...mapActions('comments', [
      'patchBodyComment'
    ]),
    clearCommentBody () {
      this.commentBody = ''
    },
    closeEditInput () {
      this.$emit('close-edit-input')
    },
    updateComment() {
      this.patchBodyComment({commentId: this.editingCommentId, commentParams: this.commentParams}).then(() => {
        this.clearCommentBody()
        this.closeEditInput()
        this.$message({
          type: 'success',
          message: this.updateConfirmMessageText
        })
      })
    }
  }
}

</script>
