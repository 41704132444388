<template>
  <div>
    <div class='py-6'>
      <div class='font-semibold'>
        {{comment.name}}
      </div>
      <div class='text-xs'>
        {{commentDateTimeString}}
      </div>
      <div v-if='!isParentEditingMode'
        class='mt-2 flex flex-row justify-between gap-x-4 break-all'>
        <div>
          {{comment.body}}
        </div>
        <div class='flex-shrink-0'>
          <button @click='openParentEditingInput()' v-if='allowEditing' class='mr-1'>
            <pencil-alt-icon size='1.2x'/>
          </button>
          <button @click='removeComment(comment.id)' v-if='allowDeleting'>
            <trash-icon size='1.2x'/>
          </button>
        </div>
      </div>
      <comment-edit-input
        v-else
        @close-edit-input='closeParentEditingInput()'
        :editing-comment-id='comment.id'
        :editing-comment-body='comment.body'
        editing-comment-parent-comment-id=''/>
      <button v-if='showReplyButton'
        @click='openReply'
        class='text-xs uppercase border p-1 mt-2'>
        {{ replyButttonText }}
      </button>
    </div>
    <div class='pl-16  bg-gray-50 border-t border-b'>
      <div v-for='(reply, index) in replyCommentsByParentCommentId(comment.id)'
        :key='`reply-${index}`'>
        <comment-reply-row 
          :reply='reply' 
          :session='session' />
      </div>
      <div v-if='showReplyCommentInput'>
        <comment-create-input :parent-comment-id='comment.id' />
        <button
          class='w-full py-2 text-center text-sm uppercase font-medium'
          @click='closeReply'>
          {{ hideButtonText }}
          <chevron-up-icon class='inline-block' size='1.2x' />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import CommentCreateInput from '@/components/comments/CommentCreateInput.vue'
import CommentEditInput   from '@/components/comments/CommentEditInput.vue'
import CommentReplyRow    from '@/components/comments/CommentReplyRow.vue'
import DateTimeStringHelpers from '@/utils/date-time-string-helpers'
import { TrashIcon, PencilAltIcon, ChevronUpIcon } from '@vue-hero-icons/outline'
import { mapState, mapGetters, mapActions } from 'vuex'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isSameOrAfter)

export default {
  name: 'CommentRow',
  props: ['comment', 'session'],
  data() {
    return {
      showReplyCommentInput: false,
      isParentEditingMode: false
    }
  },
  components: {
    CommentCreateInput,
    CommentEditInput,
    CommentReplyRow,
    TrashIcon,
    PencilAltIcon,
    ChevronUpIcon
  },
  computed: {
    ...mapState('comments', [
      'comments',
      'instance',
    ]),
    ...mapGetters('posters', [
      'selectedPosterCommentReplies',
    ]),
    ...mapGetters('events', [
      'isCmccmeEvent',
      'showTextInKorean',
    ]),
    ...mapGetters('users', [
      'isConferenceSpeaker',
      'isAdmin',
    ]),
    commentDateTimeString () {
      return DateTimeStringHelpers.toDateTime(this.comment.createdAt)
    },
    sameOrAfterVideoScheduledStartDate () {
      return dayjs().tz('Asia/Seoul').isSameOrAfter(dayjs(this.session.scheduledStartTime).tz('Asia/Seoul'), 'date')
    },
    sameVideoScheduledStartDate () {
      return dayjs().tz('Asia/Seoul').isSame(dayjs(this.session.scheduledStartTime).tz('Asia/Seoul'), 'date')
    },
    showReplyButton () {
      if (this.isCmccmeEvent) {
        return (this.isConferenceSpeaker || this.isAdmin) && this.sameOrAfterVideoScheduledStartDate
      } else {
        return true
      }
    },
    allowDeleting () {
      if (this.isCmccmeEvent) {
        return (this.isConferenceSpeaker || this.isAdmin) && this.sameOrAfterVideoScheduledStartDate
      } else {
        return true
      }
    },
    allowEditing () {
      if (this.isCmccmeEvent) {
        if (this.isConferenceSpeaker || this.isAdmin) {
          return this.sameOrAfterVideoScheduledStartDate
        } else {
          return this.comment.me.isMine && this.sameVideoScheduledStartDate
        }
      } else {
        return true
      }
    },
    replyButttonText () {
      if (this.showTextInKorean) {
        return '댓글'
      } else {
        return 'Reply'
      }
    },
    hideButtonText () {
      if (this.showTextInKorean) {
        return '숨기기'
      } else {
        return 'Hide'
      }
    },
    deleteCommentConfirmQuestionText () {
      if (this.showTextInKorean) {
        return '질의 사항을 삭제하시겠습니까?'
      } else {
        return 'Are you sure you want delete this question?'
      }
    },
    deleteConfirmMessageText () {
      if (this.showTextInKorean) {
        return '질의 사항이 삭제되었습니다.'
      } else {
        return 'This question has been successfully deleted.'
      }
    },
    confirmDeleteButtonText () {
      if (this.showTextInKorean) {
        return '예, 삭제합니다'
      } else {
        return 'Delete'
      }
    },
    dontDeleteButtonText () {
      if (this.showTextInKorean) {
        return '아니오'
      } else {
        return 'Keep Question'
      }
    },
  },
  methods: {
    ...mapActions('comments', [
      'deactivateComment'
    ]),
    replyCommentsByParentCommentId (parentCommentId) {
      return this.instance.type === 'AbstractSubmission' || this.instance.type === 'Session' ? this.comments.filter(comment => comment.parentCommentId === parentCommentId) : this.selectedPosterCommentReplies.filter(comment => comment.parentCommentId === parentCommentId)
    },
    openReply () {
      this.showReplyCommentInput = true
    },
    closeReply () {
      this.showReplyCommentInput = false
    },
    openParentEditingInput () {
      this.isParentEditingMode = true
    },
    closeParentEditingInput () {
      this.isParentEditingMode = false
    },
    removeComment (commentId) {
      this.$confirm(this.deleteCommentConfirmQuestionText, {
        confirmButtonText: this.confirmDeleteButtonText,
        cancelButtonText: this.dontDeleteButtonText,
        type: 'warning'
      }).then(() => {
        this.deactivateComment(commentId).then(() => {
          this.closeReply()
          this.$message({
            type: 'success',
            message: this.deleteConfirmMessageText
          })
        })
      }).catch(() => {})
    }
  }
}
</script>
