<template>
  <div class='border-b py-6'>
    <div class='font-semibold'>
      {{reply.name}}
    </div>
    <div class='text-xs'>
      {{replyDateTimeString}}
    </div>
    <div v-if='!isReplyEditingMode'
         class='mt-2 flex flex-row justify-between gap-x-4 break-all'>
      <div>
        {{reply.body}}
      </div>
      <div class='flex-shrink-0' v-if='showEditAndDeleteButton'>
        <button @click='openReplyEditingInput()' class='mr-1'><pencil-alt-icon size='1.2x'/></button>
        <button @click='removeComment(reply.id)'><trash-icon size='1.2x'/></button>
      </div>
    </div>
    <comment-edit-input
      v-else
      @close-edit-input='closeReplyEditingInput()'
      :editing-comment-id='reply.id'
      :editing-comment-body='reply.body'
      :editing-comment-parent-comment-id='reply.parentCommentId'/>
  </div>
</template>

<script>
import CommentEditInput   from '@/components/comments/CommentEditInput.vue'
import DateTimeStringHelpers from '@/utils/date-time-string-helpers'
import { TrashIcon, PencilAltIcon } from '@vue-hero-icons/outline'
import { mapGetters, mapActions } from 'vuex'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isSameOrAfter)

export default {
  name: 'CommentReplyRow',
  props: ['reply', 'session'],
  data() {
    return {
      isReplyEditingMode: false
    }
  },
  computed: {
    ...mapGetters('users', [
      'isConferenceSpeaker',
      'isAdmin',
    ]),
    ...mapGetters('events', [
      'isCmccmeEvent',
      'showTextInKorean',
    ]),
    replyDateTimeString () {
      return DateTimeStringHelpers.toDateTime(this.reply.createdAt)
    },
    sameOrAfterVideoScheduledStartDate () {
      return dayjs().tz('Asia/Seoul').isSameOrAfter(dayjs(this.session.scheduledStartTime).tz('Asia/Seoul'), 'date')
    },
    sameVideoScheduledStartDate () {
      return dayjs().tz('Asia/Seoul').isSame(dayjs(this.session.scheduledStartTime).tz('Asia/Seoul'), 'date')
    },
    showEditAndDeleteButton () {
      if (this.isCmccmeEvent) {
        return (this.isConferenceSpeaker || this.isAdmin) && this.sameOrAfterVideoScheduledStartDate
      } else {
        return true
      }
    },
    deleteCommentConfirmQuestionText () {
      if (this.showTextInKorean) {
        return '댓글을 삭제하시겠습니까?'
      } else {
        return 'Are you sure you want delete this comment?'
      }
    },
    deleteConfirmMessageText () {
      if (this.showTextInKorean) {
        return '댓글이 삭제되었습니다.'
      } else {
        return 'This comment has been successfully deleted.'
      }
    },
    confirmDeleteButtonText () {
      if (this.showTextInKorean) {
        return '예, 삭제합니다'
      } else {
        return 'Delete'
      }
    },
    dontDeleteButtonText () {
      if (this.showTextInKorean) {
        return '아니오'
      } else {
        return 'Keep Comment'
      }
    },
  },
  components: {
    CommentEditInput,
    TrashIcon,
    PencilAltIcon
  },
  methods: {
    ...mapActions('comments', [
      'deactivateComment'
    ]),
    openReplyEditingInput () {
      this.isReplyEditingMode = true
    },
    closeReplyEditingInput () {
      this.isReplyEditingMode = false
    },
    removeComment (commentId) {
      this.$confirm(this.deleteCommentConfirmQuestionText, {
        confirmButtonText: this.confirmDeleteButtonText,
        cancelButtonText: this.dontDeleteButtonText,
        type: 'warning'
      }).then(() => {
        this.deactivateComment(commentId).then(() => {
          this.$message({
            type: 'success',
            message: this.deleteConfirmMessageText
          })
        })
      }).catch(() => {})
    }
  }
}
</script>
