<template>
  <div class='p-4 border rounded-md bg-white'>
    <textarea class='w-full p-2'
              rows='3'
              v-model='commentBody'
              :placeholder='createInputPlaceholderMessage'>
    </textarea>
    <div class='flex flex-row justify-end '>
      <button class='border text-center p-2 text-sm font-semibold'
              style='background-color: var(--eventMainColor); color: #FFF;'
              :class='commentSubmitButtonClass'
              :disabled='!hasCommentBody'
              @click='submitComment'>{{submitButtonText}}</button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
export default {
  name: 'CommentCreateInput',
  props: ['parentCommentId'],
  data() {
    return {
      commentBody: ''
    }
  },
  computed: {
    ...mapState('comments', [
      'instance'
    ]),
    ...mapGetters('events', [
      'showingEventId',
      'showTextInKorean',
    ]),
    hasCommentBody () {
      return this.commentBody
    },
    commentSubmitButtonClass () {
      return this.hasCommentBody ? '' : 'opacity-25'
    },
    commentParams () {
      return {
        comment: {
          body: this.commentBody,
          instance_type: this.instance.type,
          instance_id: this.instance.id,
          parent_comment_id: this.parentCommentId,
          event_id: this.showingEventId
        }
      }
    },
    createInputPlaceholderMessage () {
      if (this.showTextInKorean) {
        if (this.parentCommentId) {
          return '질의 사항의 댓글을 입력해 주십시오.'
        } else {
          return '강의에 대하여 궁금한 점을 기재해 주십시오.'
        }
      } else {
        return ''
      }
    },
    successMessage () {
      if (this.showTextInKorean) {
        if (this.parentCommentId) {
          return '댓글이 등록되었습니다.'
        } else {
          return '질의 사항이 등록되었습니다.'
        }
      } else {
        if (this.parentCommentId) {
          return 'Your question has been updated.'
        } else {
          return 'Your question has been added.'
        }
      }
    },
    submitButtonText () {
      if (this.showTextInKorean) {
        if (this.parentCommentId) {
          return '댓글 남기기'
        } else {
          return '질문하기'
        }
      } else {
        return 'Submit'
      }
    },
  },
  methods: {
    ...mapActions('comments', [
      'postComment'
    ]),
    clearCommentBody () {
      this.commentBody = ''
    },
    submitComment() {
      this.postComment(this.commentParams).then(() => {
        this.clearCommentBody()
        this.$message({
          type: 'success',
          message: this.successMessage,
        })
      })
    }
  }
}

</script>
