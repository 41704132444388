<template>
  <div v-if='hasValidToken' 
    class='w-full'>
    <h2 class='text-gray-700 text-xl font-semibold mb-4'>
      Q&A ({{commentsCount}})
    </h2>
    <comment-create-input v-if='showCommentCreateInput' class='mb-4' />
    <div v-if='comments.length > 0'>
      <div v-for='(comment, index) in comments'
            :key='`comment-${index}`'
            class='px-2'>
        <comment-row :comment='comment' :session='session' />
      </div>
    </div>
    <div v-else class='bg-gray-100 p-8 rounded-md flex flex-col justify-center items-center'>
      <message-circle-icon class='mb-2 text-gray-500' size='24'/>
      <p class='text-gray-900 text-sm lg:text-base'>{{noQuestionsText}}</p>
    </div>
  </div>
  <div v-else-if='showMessageWhenNotLoggedIn'
    class='flex flex-col justify-center items-center text-center py-16 flex-grow bg-gray-50 uppercase text-gray-600 hover:text-gray-800 cursor-pointer hover:font-bold'
    @click='goToLogin'>
    <div>{{onlyLoginCanAskText}}</div>
    <div v-html='pleaseLoginText'></div>
  </div>
  <div v-else><!-- no login, no comments --></div>
</template>

<script>
import { mapState, mapGetters }       from 'vuex'
import CommentCreateInput from '@/components/comments/CommentCreateInput.vue'
import CommentRow         from '@/components/comments/CommentRow.vue'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isSameOrAfter)
import { MessageCircleIcon } from 'vue-tabler-icons'

export default {
  name: 'Comments',
  props: {
    comments: {
      type: Array,
      required: true
    },
    showMessageWhenNotLoggedIn: {
      type: Boolean,
      default: false
    },
    session: {
      type: Object,
      default: null,
    }
  },
  components: {
    CommentCreateInput,
    CommentRow,
    MessageCircleIcon,
  },
  computed: {
    ...mapState('users', [
      'hasValidToken',
    ]),
    ...mapGetters('users', [
      'isConferenceSpeaker',
      'isAdmin',
    ]),
    ...mapGetters('events', [
      'isCmccmeEvent',
      'showTextInKorean',
    ]),
    commentsCount () {
      return this.comments.length
    },
    noQuestionsText () {
      if (this.showTextInKorean) {
        return '등록된 질문이 없습니다.'
      } else {
        return 'No questions have been submitted yet.'
      }
    },
    onlyLoginCanAskText () {
      if (this.showTextInKorean) {
        return '등록하신 분들만 질의하실 수 있습니다.'
      } else {
        return 'Only logged in users can ask questions.'
      }
    },
    pleaseLoginText () {
      if (this.showTextInKorean) {
        return "등록하신 분들은 <span class='ml-1 underline'>로그인</span> 부탁 드립니다."
      } else {
        return 'Please login to ask questions.'
      }
    },
    sameOrAfterVideoScheduledStartDate () {
      return dayjs().tz('Asia/Seoul').isSameOrAfter(dayjs(this.session.scheduledStartTime).tz('Asia/Seoul'), 'date')
    },
    sameVideoScheduledStartDate () {
      return dayjs().tz('Asia/Seoul').isSame(dayjs(this.session.scheduledStartTime).tz('Asia/Seoul'), 'date')
    },
    showCommentCreateInput () {
      if (this.isCmccmeEvent) {
        if (this.isConferenceSpeaker || this.isAdmin) {
          return this.sameOrAfterVideoScheduledStartDate
        } else {
          return this.sameVideoScheduledStartDate
        }
      } else {
        return true
      }
    },
  },
  methods: {
    goToLogin () {
      this.$router.push({name: 'Login'})
    },
  },
}
</script>


